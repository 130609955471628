import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { create } from 'jss';
import rtl from 'jss-rtl';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { jssPreset, StylesProvider, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import GlobalStyles from 'src/components/GlobalStyles';
import ScrollReset from 'src/components/ScrollReset';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import SlideOnStart from 'src/components/SliderOnStart';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import { createTheme } from 'src/theme';
import routes, { renderRoutes } from 'src/routes';
import { Capacitor } from '@capacitor/core';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getMessaging,
  getToken,
  onMessage,
  deleteToken
} from 'firebase/messaging';
import { Box, Typography } from '@material-ui/core';

import { useSnackbar } from 'notistack';
import { appConfig } from './config';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const history = createBrowserHistory();
const App = () => {
  const { settings, saveSettings } = useSettings();
  // const { enqueueSnackbar } = useSnackbar();

  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });

  const Msg = ({ titolo, body }) => {
    return (
      <Box display="flex" flexDirection="column">
        <strong>{titolo}</strong>
        <Typography>{body}</Typography>
      </Box>
    );
  };

  if (appConfig.enablePushNotifications) {
    try {
      const messaging = getMessaging();
      onMessage(messaging, payload => {
        console.log('Message received. ', payload);
        toast(<Msg titolo={payload.data.title} body={payload.data.body} />);
      });
    } catch {}
  }

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      saveSettings({ sliderShown: false });
    }
    console.log(settings);
    if (settings.lang == 'ar') {
      document.body.dir = 'rtl';
    } else {
      document.body.dir = 'ltr';
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider dense maxSnack={3}>
            <Router history={history}>
              <AuthProvider>
                <ToastContainer position="top-center" />
                <GlobalStyles />
                <ScrollReset />
                <GoogleAnalytics />
                <SlideOnStart />
                {renderRoutes(routes)}
              </AuthProvider>
            </Router>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default App;
