import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import axiosFE from 'axios';
import { getCookie } from 'src/restBox/restBox';
import ReactHtmlParser from 'react-html-parser';

import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { appConfig } from 'src/config';

const baseurlRest = appConfig.restPrefix;
const instance = axiosFE.create({
  baseURL: baseurlRest,
  headers: {
    'content-type': 'application/x-www-form-urlencoded',
    'Access-Control-Expose-Headers': baseurlRest,
    jwt: window.localStorage.getItem('accessToken')
  }
});

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

const Search = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [results, setResults] = useState([]);
  const [msg, setMsg] = useState('');

  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      //setResults(response.data.results);

      const response = await instance.get('/procs/search/', {
        params: {
          text: value
        },
        headers: {
          jwt: window.localStorage.getItem('accessToken')
        }
      });
      console.log(response);
      const lista = [];
      response.data.response.map(e => {
        lista.push({ description: e.body, title: e.linkText, link: e.link });
      });
      if (lista.length == 0) {
        setMsg(`${t('noResults')} "${value}"`);
      }
      setResults(lista);
    } catch (err) {
      console.error(err);
      enqueueSnackbar(t('error'), {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {console.log(results)}
      <Tooltip title={t('ricerca')}>
        <IconButton color="inherit" onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                {t('ricerca')}
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={event => setValue(event.target.value)}
                placeholder={t('paroleDaCercare')}
                value={value}
                variant="outlined"
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSearch}
              >
                {t('ricerca')}
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {results.length != 0 ? (
                    results.map((result, i) => (
                      <Box key={i} mb={2}>
                        <Link
                          variant="h4"
                          color="textPrimary"
                          component={RouterLink}
                          to={'/app/' + result.link}
                        >
                          {result.title}
                        </Link>
                        <Typography variant="body2" color="textPrimary">
                          {ReactHtmlParser(result.description)}
                        </Typography>
                      </Box>
                    ))
                  ) : (
                    <Box mb={2}>
                      <Typography variant="body2" color="textPrimary">
                        {msg}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
};

export default Search;
