import axios from 'axios';
import { appConfig } from 'src/config';

const restPrefix = appConfig.restPrefix;
const http = axios.create({
  baseURL: restPrefix
});

export function headerNotifications() {
  return http
    .post(`procs/notify/header`, {
      jwt: window.localStorage.getItem('accessToken')
    })
    .then(response => {
      const resp = response.data;
      var notifyList = resp.response;
      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return notifyList;
    })
    .catch(error => {
      const err = error.response.data;
      return err;
    });
}

export function notifications(f, s, p, r = '', g = 20) {
  if (p == 'all') p = '';
  return http
    .get('procs/notify/list/', {
      params: {
        filtro: f,
        stato: s,
        raggruppamento: r,
        grandezza: g,
        codProcesso: p
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken')
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => {
      const err = error.response.data;
      return err;
    });
}
export function notificationsHeader(filtro, stato, processo) {
  let codProcesso = processo == 'all' ? '' : processo;
  return http
    .get('procs/notify/list/', {
      params: {
        filtro: filtro,
        stato: stato,
        raggruppamento: '',
        codProcesso: codProcesso
      },
      headers: {
        jwt: window.localStorage.getItem('accessToken')
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => {
      const err = error.response.data;
      return err;
    });
}

export function notificationsKanban(f, s, p) {
  if (p == 'all') p = '';
  return http
    .get('procs/notify/kanban/', {
      params: {
        filtro: f,
        stato: s,
        codProcesso: p
      },
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Access-Control-Expose-Headers': restPrefix,
        jwt: window.localStorage.getItem('accessToken')
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => {
      const err = error.response.data;
      return err;
    });
}

export function getProcesses() {
  return http
    .get('processes/', {
      headers: {
        jwt: window.localStorage.getItem('accessToken')
      }
    })
    .then(response => {
      const resp = response.data;

      const jwt = resp.jwt;
      if (jwt !== '') {
        localStorage.setItem('accessToken', jwt);
      }
      return resp;
    })
    .catch(error => {
      const err = error.response.data;
      return err;
    });
}
