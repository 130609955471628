import React, { useState, useRef } from 'react';
import { capitalCase } from 'change-case';
import {
  Badge,
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Popover,
  SvgIcon,
  Switch,
  TextField,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Settings as SettingsIcon } from 'react-feather';
import useSettings from 'src/hooks/useSettings';
import { THEMES } from 'src/constants';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import ListSubheader from '@material-ui/core/ListSubheader';
import { viewConfig, appConfig } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { updateLang } from 'src/api/functions-api';
import { useHistory } from 'react-router';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  badge: {
    height: 10,
    width: 10,
    borderRadius: 5,
    marginTop: 10,
    marginRight: 5
  },
  popover: {
    width: 320,
    padding: theme.spacing(2)
  }
}));

const displaySL = appConfig.displaySelectLanguages;
const displayTHEME = viewConfig.theme;
const languages_ = appConfig.availableLanguages;

const Settings = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { settings, saveSettings } = useSettings();
  const [isOpen, setOpen] = useState(false);
  const [values, setValues] = useState({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  const { t } = useTranslation();

  const { user } = useAuth();
  const history = useHistory();

  const availableLanguages = languages_.split(',');

  let languageMap = null;
  let selected = localStorage.getItem('i18nextLng');

  // const [select, setSelect] = useState(selected);

  availableLanguages.map(x => {
    switch (x) {
      case 'it':
        languageMap = {
          ...languageMap,
          it: { label: t('italiano'), dir: 'ltr', active: true }
        };
        break;
      case 'en':
        languageMap = {
          ...languageMap,
          en: { label: t('inglese'), dir: 'ltr', active: true }
        };
        break;
      case 'ar':
        languageMap = {
          ...languageMap,
          ar: { label: t('arabo'), dir: 'ltr', active: true }
        };
        break;
      case 'fr':
        languageMap = {
          ...languageMap,
          fr: { label: t('francese'), dir: 'ltr', active: true }
        };
        break;
      case 'pu':
        languageMap = {
          ...languageMap,
          pu: { label: t('punjabi'), dir: 'ltr', active: true }
        };
        break;
    }
  });

  // const languageMap = {
  //   it: { label: t('italiano'), dir: 'ltr', active: true },
  //   en: { label: t('inglese'), dir: 'ltr', active: false },
  //   ar: { label: t('arabo'), dir: 'ltr', active: false },
  //   fr: { label: t('francese'), dir: 'ltr', active: false },
  //   pu: { label: t('punjabi'), dir: 'ltr', active: false }
  // };

  if (
    selected != 'it' &&
    selected != 'en' &&
    selected != 'fr' &&
    selected != 'pu' &&
    selected != 'ar'
  ) {
    selected = 'it';
  }
  // const [menuAnchor, setMenuAnchor] = React.useState(null);
  // React.useEffect(() => {
  //   document.body.dir = languageMap[selected].dir;
  // }, [menuAnchor, selected]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (field, value) => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = () => {
    saveSettings(values);
    setOpen(false);
  };

  const handleLng = async lng => {
    let langToChange = '';

    switch (lng) {
      case t('italiano'):
        langToChange = 'it';
        break;
      case t('arabo'):
        langToChange = 'ar';
        break;
      case t('inglese'):
        langToChange = 'en';
        break;
      case t('francese'):
        langToChange = 'fr';
        break;
      case t('punjabi'):
        langToChange = 'pu';
        break;
      default:
        langToChange = 'it';
        break;
    }
    i18next.changeLanguage(langToChange);
    // setSelect(langToChange);
    saveSettings({
      ...values,
      lang: langToChange,
      direction: langToChange == 'ar' ? 'rtl' : 'ltr'
    });
    moment.locale(langToChange);
    document.body.dir = langToChange == 'ar' ? 'rtl' : 'ltr';
    const resp = await updateLang(user.id, langToChange);
    if (resp.server == 200) {
      history.go(0);
    }
  };

  return viewConfig.settings == true ? (
    <>
      <Tooltip title={t('impostazioni')}>
        <Badge
          color="secondary"
          variant="dot"
          classes={{ badge: classes.badge }}
        >
          <IconButton color="inherit" onClick={handleOpen} ref={ref}>
            <SvgIcon fontSize="small">
              <SettingsIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('impostazioni')}
        </Typography>
        {displaySL && (
          <Box mt={4}>
            <TextField
              fullWidth
              label={t('select_language')}
              name="language"
              onChange={event => handleLng(event.target.value)}
              select
              SelectProps={{ native: true }}
              value={languageMap[selected].label}
              variant="outlined"
            >
              {Object.keys(languageMap)?.map(item => (
                <option key={item} id={item}>
                  {languageMap[item].label}
                </option>
              ))}
            </TextField>
          </Box>
        )}

        {displayTHEME && (
          <Box mt={4}>
            <TextField
              fullWidth
              label={t('tema')}
              name="theme"
              onChange={event => handleChange('theme', event.target.value)}
              select
              SelectProps={{ native: true }}
              value={values.theme}
              variant="outlined"
            >
              {Object.keys(THEMES).map(theme => (
                <option key={theme} value={theme}>
                  {capitalCase(theme)}
                </option>
              ))}
            </TextField>
          </Box>
        )}
        {/* <Box mt={2} px={1}>
          <FormControlLabel
            control={
              <Switch
                checked={values.direction === 'rtl'}
                edge="start"
                name="direction"
                onChange={event =>
                  handleChange(
                    'direction',
                    event.target.checked ? 'rtl' : 'ltr'
                  )
                }
              />
            }
            label="RTL"
          />
        </Box> */}
        <Box mt={2}>
          <Button
            variant="contained"
            color="secondary"
            fullWidth
            onClick={handleSave}
          >
            {t('salvaImpostazioni')}
          </Button>
        </Box>
      </Popover>
    </>
  ) : null;
};

export default Settings;
