import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationIT from './locales/it/translation.json';
import translationAR from './locales/ar/translation.json';
import translationFR from './locales/fr/translation.json';
import translationPU from './locales/pu/translation.json';
import { appConfig } from 'src/config';
const fallbackLng = ['it'];

const languages_ = appConfig.availableLanguages;
const availableLanguages = languages_.split(',');
let resources = {};

availableLanguages.map(x => {
  switch (x) {
    case 'it':
      resources = {
        ...resources,
        it: {
          translation: translationIT
        }
      };
      break;
    case 'en':
      resources = {
        ...resources,
        en: {
          translation: translationEN
        }
      };
      break;
    case 'ar':
      resources = {
        ...resources,
        ar: {
          translation: translationAR
        }
      };
      break;
    case 'fr':
      resources = {
        ...resources,
        fr: {
          translation: translationFR
        }
      };
      break;
    case 'pu':
      resources = {
        ...resources,
        pu: {
          translation: translationPU
        }
      };
      break;
  }
});

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'it-IT',
    resources,

    // detection: {
    //   checkWhitelist: true
    // },

    debug: true,

    interpolation: {
      escapeValue: false
    }
  });
if (availableLanguages.length == 1) {
  i18n.changeLanguage(availableLanguages[0]);
}

export default i18n;
