/* eslint-disable no-use-before-define */
import React, { useEffect, useState } from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import * as Icon from 'react-feather';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';
import NavItem from './NavItem';
import { getCookie } from 'src/restBox/restBox';
import { useTranslation } from 'react-i18next';

// const sections = [
//   {
//     subheader: 'Reports',
//     items: [
//       {
//         title: 'Dashboard',
//         icon: PieChartIcon,
//         href: '/app/reports/dashboardProcs'
//       },
//       {
//         title: 'Dashboard Template',
//         icon: PieChartIcon,
//         href: '/app/reports/dashboard'
//       },
//       {
//         title: 'Dashboard Alternative',
//         icon: BarChartIcon,
//         href: '/app/reports/dashboard-alternative'
//       }
//     ]
//   },
//   {
//     subheader: 'Management',
//     items: [
//       {
//         title: 'Customers',
//         icon: UsersIcon,
//         href: '/app/management/customers',
//         items: [
//           {
//             title: 'List Customers',
//             href: '/app/management/customers'
//           },
//           {
//             title: 'View Customer',
//             href: '/app/management/customers/1'
//           },
//           {
//             title: 'Edit Customer',
//             href: '/app/management/customers/1/edit'
//           }
//         ]
//       },
//       {
//         title: 'Products',
//         icon: ShoppingCartIcon,
//         href: '/app/management/products',
//         items: [
//           {
//             title: 'List Products',
//             href: '/app/management/products'
//           },
//           {
//             title: 'Create Product',
//             href: '/app/management/products/create'
//           }
//         ]
//       },
//       {
//         title: 'Orders',
//         icon: FolderIcon,
//         href: '/app/management/orders',
//         items: [
//           {
//             title: 'List Orders',
//             href: '/app/management/orders'
//           },
//           {
//             title: 'View Order',
//             href: '/app/management/orders/1'
//           },
//           {
//             title: 'View Order 2',
//             href: '/app/management/orders/2'
//           }
//         ]
//       },
//       {
//         title: 'Invoices',
//         icon: ReceiptIcon,
//         href: '/app/management/invoices',
//         items: [
//           {
//             title: 'List Invoices',
//             href: '/app/management/invoices'
//           },
//           {
//             title: 'View Invoice',
//             href: '/app/management/invoices/1'
//           }
//         ]
//       }
//     ]
//   },
//   {
//     subheader: 'Applications',
//     items: [
//       {
//         title: 'Projects Platform',
//         href: '/app/projects',
//         icon: BriefcaseIcon,
//         items: [
//           {
//             title: 'Overview',
//             href: '/app/projects/overview'
//           },
//           {
//             title: 'Browse Projects',
//             href: '/app/projects/browse'
//           },
//           {
//             title: 'Create Project',
//             href: '/app/projects/create'
//           },
//           {
//             title: 'View Project',
//             href: '/app/projects/1'
//           }
//         ]
//       },
//       {
//         title: 'Social Platform',
//         href: '/app/social',
//         icon: ShareIcon,
//         items: [
//           {
//             title: 'Profile',
//             href: '/app/social/profile'
//           },
//           {
//             title: 'Feed',
//             href: '/app/social/feed'
//           }
//         ]
//       },
//       {
//         title: 'Kanban',
//         href: '/app/kanban',
//         icon: TrelloIcon
//       },
//       {
//         title: 'Mail',
//         href: '/app/mail',
//         icon: MailIcon
//       },
//       {
//         title: 'Chat',
//         href: '/app/chat',
//         icon: MessageCircleIcon,
//         info: () => <Chip color="secondary" size="small" label="Updated" />
//       },
//       {
//         title: 'Calendar',
//         href: '/app/calendar',
//         icon: CalendarIcon,
//         info: () => <Chip color="secondary" size="small" label="Updated" />
//       }
//     ]
//   },

//   {
//     subheader: 'Extra',
//     items: [
//       {
//         title: 'Charts',
//         href: '/app/extra/charts',
//         icon: BarChartIcon,
//         items: [
//           {
//             title: 'Apex Charts',
//             href: '/app/extra/charts/apex'
//           }
//         ]
//       },
//       {
//         title: 'Forms',
//         href: '/app/extra/forms',
//         icon: EditIcon,
//         items: [
//           {
//             title: 'Formik',
//             href: '/app/extra/forms/formik'
//           },
//           {
//             title: 'Redux Forms',
//             href: '/app/extra/forms/redux'
//           }
//         ]
//       },
//       {
//         title: 'Editors',
//         href: '/app/extra/editors',
//         icon: LayoutIcon,
//         items: [
//           {
//             title: 'DraftJS Editor',
//             href: '/app/extra/editors/draft-js'
//           },
//           {
//             title: 'Quill Editor',
//             href: '/app/extra/editors/quill'
//           }
//         ]
//       }
//     ]
//   }
// ];

function renderNavItems({ items, pathname, depth = 0 }) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const getIcon = notificationType => {
  return Icon[notificationType];
};

const NavBar = ({ onMobileClose, openMobile }) => {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useAuth();
  const { t } = useTranslation();
  const menu = user.menu.root;
  const dbInfo = user.dbInfo;
  const [isDesigner, setisDesigner] = useState(false);
  console.log(location.pathname);

  useEffect(() => {
    if (location.pathname.includes('ProcesDesign')) {
      setisDesigner(true);
    } else {
      setisDesigner(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const sections = [];

  function getSections() {
    let arrayMenu = [...sections];
    if (!dbInfo) {
      arrayMenu.push({
        items: [
          {
            title: t('dashboard'),
            icon: PieChartIcon,
            href: '/app/reports/dashboardProcs'
          }
        ]
      });
    }
    if (menu) {
      menu.map(m => {
        if (m.children) {
          let el = { subheader: m.description, items: [] };
          arrayMenu.push(el);
          m.children.map(c => {
            el.items.push({
              title: c.description,
              href:
                '/app/' +
                c.page +
                '/' +
                c.codeProcess +
                '/' +
                c.codeStep +
                '/' +
                (c.pars && c.pars != '' ? c.pars : ''),
              //icon: MessageCircleIcon
              icon: getIcon(c.icon),
              page: c.page,
              proc: c.codeProcess,
              step: c.codeStep,
              pars: c.pars
            });
          });
        }
      });
    }

    return arrayMenu;
  }

  const content = (
    <Box height="100%" style={{ paddingTop: "env(safe-area-inset-top)", paddingBottom: "env(safe-area-inset-bottom)"}} display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box p={2} display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/app/account">
              <Avatar alt="User" className={classes.avatar} src={user.avatar} />
              {/* <Avatar alt="User" className={classes.avatar} src={'/static/images/avatars/' + user + '.png'} /> */}
            </RouterLink>
          </Box>
          <Box mt={2} textAlign="center">
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {user.name}
            </Link>
            <Typography variant="body2" color="textSecondary">
              {t('profilo')}:{' '}
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/app/account"
              >
                {user.tier}
              </Link>
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box p={2}>
          {getSections().map((section, index) => (
            <List
              key={index}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
        {/*
        <Divider />
        <Box p={2}>
          <Box p={2} borderRadius="borderRadius" bgcolor="background.dark">
            <Typography variant="h6" color="textPrimary">
              Need Help?
            </Typography>
            <Link
              variant="subtitle1"
              color="secondary"
              component={RouterLink}
              to="/docs"
            >
              Check our docs
            </Link>
          </Box>
        </Box>
        */}
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      {isDesigner ? (
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      ) : (
        <>
          <Hidden lgUp>
            <Drawer
              anchor="left"
              classes={{ paper: classes.mobileDrawer }}
              onClose={onMobileClose}
              open={openMobile}
              variant="temporary"
            >
              {content}
            </Drawer>
          </Hidden>
          <Hidden mdDown>
            <Drawer
              anchor="left"
              classes={{ paper: classes.desktopDrawer }}
              open
              variant="persistent"
            >
              {content}
            </Drawer>
          </Hidden>
        </>
      )}
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
