export const APP_VERSION = '3.1.0';

export const ENABLE_REDUX_DEV_TOOLS = true;

export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN',
  TECNO: 'TECNO',
  HUB: 'HUB',
  INDUSTRY: 'INDUSTRY',
  PA: 'PA',
  ZANIBELLI: 'ZANIBELLI',
  SICILIA: 'SICILIA',
  MPS: 'MPS',
  BCC: 'BCC',
  PUNTACCAPO: 'PUNTACCAPO',
  DEMO1: 'DEMO1',
  MORGANTI: 'MORGANTI',
  GG2: 'GG2',
  SIA: 'SIA',
  ALFAMATION: 'ALFAMATION',
  DLL: 'DLL'
};
