import React from 'react';
import { appConfig } from 'src/config';

const logo_path = appConfig.logoPath;

const Logo2 = props => {
  return <img alt="Logo" src={logo_path} height="100" {...props} />;
};

export default Logo2;
