import React from 'react';
import useAuth from 'src/hooks/useAuth';
import { appConfig } from 'src/config';

const Logo = props => {
  const { user } = useAuth();
  return <img alt="Logo" src={appConfig.logoPath} height="50px" {...props} />;
};

export default Logo;
