import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, styled } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import clsx from 'clsx';

import { useLocation } from 'react-router-dom';
import "../style.css"
const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
    // padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
    // backgroundColor: "blueviolet",
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  wrapperDesigner: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const DashboardLayout = ({ children }) => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [isDesigner, setisDesigner] = useState(false);
  const location = useLocation();
  console.log(location.pathname);

  const classes = useStyles();

  useEffect(() => {
    if (location.pathname.includes('ProcesDesign')) {
      setisDesigner(true);
    } else {
      setisDesigner(false);
    }
  }, [location.pathname]);

  return (
    <div class="root"  className={classes.root}>
      <TopBar
        isDesigner={isDesigner}
        onMobileNavOpen={() => setMobileNavOpen(true)}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div
        className={clsx({
          [classes.wrapper]: !isDesigner,
          [classes.wrapperDesigner]: isDesigner
        })}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node
};

export default DashboardLayout;
